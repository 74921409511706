<template>
  <div class="no-found-page">
    <div class="number">
      404
    </div>
    <div class="text">
      页面找不到啦
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
  }
};
</script>
<style lang="scss" scoped>
.no-found-page {
  text-align: center;
  width: 800px;
  margin: auto;
  font-style: oblique;
  .number {
    font-size: 120px;
  }
  .text {
    font-size: 24px;
  }
}
</style>